import { onDutySignApi } from '@/api/attendance/onDutySign/onDutySignApi';

export default class onDutySignService {

    private api: any;

    constructor() {
        this.api = new onDutySignApi();
    }

    //提交
    public onDutySign(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.onDutySign(params).then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public hasPermission(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.hasPermission().then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 外出签到列表
     * @param userId 
     * @param startDate 
     * @param endDate 
     * @param page 
     * @param size 
     * @returns 
     */
    public onDutySignList(userId: string, startDate: string, endDate: string, page: number, size: number): Promise<any> {
        const params = {
            userId,
            page,
            size,
            startDate,
            endDate,
        }
        return new Promise((resolve, reject) => {
            this.api.onDutySignList(params).then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取详情
     * @param id 
     * @returns 
     */
    public onDutySignDetail(id: string): Promise<any> {
        const params = {
            id
        }
        return new Promise((resolve, reject) => {
            this.api.onDutySignDetail(params).then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    /**
     * 上传附件
     * @param params 
     * @returns 
     */
    public uploadFile(params: any, path: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.uploadFile(params, path).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });

    }

    public uploadFileBase64(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.uploadFileBase64(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getUploadFilePath(): string {
        return this.api.getUploadFilePath();
    }
}