import AttendanceBaseService from '../base/AttendanceBaseService';

export class onDutySignApi extends AttendanceBaseService {

    constructor() {
        super("ondutySign");
    }

    /**
     * 提交
     * @param params 
     * @returns 
     */
    public onDutySign(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request('sign', this.rest.METHOD_POST, params).then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public hasPermission(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request('hasPermission', this.rest.METHOD_GET, null).then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public onDutySignList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request('list', this.rest.METHOD_GET, params).then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public onDutySignDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request('detail', this.rest.METHOD_GET, params).then((res: any) => {
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 上传附件
     * @param params 
     * @returns 
     */
    public uploadFile(params: any, path: string): Promise<any> {
        return this.request('uploadCoverImage', this.rest.METHOD_UPLOAD, params, [path], ['file']);
    }

    public uploadFileBase64(params: any): Promise<any> {
        return this.request('uploadCoverImage', this.rest.METHOD_POST, params);
    }

    public getUploadFilePath(): string {
        const path = 'uploadCoverImage';
        return this.getUrl(path);
    }
}