
import { defineComponent, onMounted, reactive, ref } from "vue";
import onDutySignService from "@/services/attendance/onDutySign/onDutySignService";
import Header from "@/components/Header.vue";
import Location from "@/components/Location.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "OutSignInDetail",
  components: { Header, Location },
  setup() {
    const router = useRoute();
    const record_id = router.params.id;
    const longitude = router.params.longitude;
    const latitude = router.params.latitude;

    const detail = reactive({
      userName: "",
      date: "",
      signTime: "",
      address: "",
      longitude: 0,
      latitude: 0,
      descrip: "",
    });

    detail.longitude = typeof longitude == "string" ? parseFloat(longitude) : 0;
    detail.latitude = typeof latitude == "string" ? parseFloat(latitude) : 0;

    const service = new onDutySignService();

    const loadDetail = () => {
      const id = typeof record_id == "string" ? record_id : "";
      service
        .onDutySignDetail(id)
        .then(
          (res: any) => {
            if (res) {
              const result = res.data;
              detail.userName = result.userName;
              detail.date = result.date;
              detail.signTime = result.signTime;
              detail.address = result.address;
              detail.descrip = result.descrip;
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const initDate = () => {
      loadDetail();
    };

    onMounted(() => {
      initDate();
    });

    return { detail };
  },
});
